.faq-page {
    padding: 0 28px 70px;
    background-color: #EFEFEF;
}

.faq-page h1 {
    color: #2a2a2a;
    line-height: 24px;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 1px;
}

.faq-page .MuiAccordionSummary-content {
    color: #1d1d1b;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;

    max-width: calc(100% - 30px);
}

.faq-page .MuiButtonBase-root.MuiAccordionSummary-root {
    border-bottom: 1px solid #6b6b6b;
    background: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 100%;
}
.faq-page .MuiButtonBase-root.Mui-expanded {
    border-bottom: 2px solid #f8ab28;
    margin-bottom: -2px;
}

.faq-page .MuiPaper-root {
    background: none;
}

.faq-page .MuiAccordionDetails-root {
    line-height: 17px;
    font-size: 12px;
    font-weight: 500;
    padding: 28px 0;
}

.faq-page .btn-show {
    line-height: 18px;
    font-size: 15px;
    font-weight: 700;
    padding-top: 28px;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #2a2a2a;
}

.faq-page .MuiAccordionSummary-expandIcon {
    padding: 0;
    border: none !important;
    margin-right: 0;
}

.faq-page .dropdown-arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

.faq-page .container-slides {
    margin-top: 20px;
}

.faq-page .swiper-pagination-bullet {
    background: rgba(29, 29, 27, 0.25);
}
.faq-page .swiper-pagination-bullet-active {
    background: #1D1D1B;
}

.faq-page .swiper-slide img {
    width: 100%;
    height: 100%;
    max-width: 240px;
    max-height: 340px;
}

.faq-page .container-slide-image {
    margin: 20px auto 30px;
    position: relative;
}

.faq-page .header-container {
    padding: 70px 0 48px;
    display: flex;
    align-items: center;
}
.faq-page .header-container img {
    margin-left: 15px;
}

.pointer-css{
    position: absolute;
    background: transparent url('https://wmstatic.global.ssl.fastly.net/ml/270723-f-3fee63a2-6a44-4275-b8a7-8964030d372e.png') 0 0 no-repeat;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    animation: pulse 1s steps(11) infinite;
}

@keyframes pulse {
    100% {
        background-position: -660px, 0;
    }
}