.signup-wrapper {
  @include page-wrapper-position;
  padding-top: rem(40);

  &.signup-wrapper-registration {
    // padding-top: $header-height; //if header is fixed

    .page-header {
      &.sticky-top {
        top: $header-height;
      }
    }

    .signup-info {
      margin-top: rem(30);
      margin-bottom: rem(30);

      span {
        font-size: rem(12);
        font-weight: 500;
      }
    }
  }
}

.signup-info-consents-step {
  background: rgba(29, 29, 27, 1);
}

.signup-consents-marketing-step {
  padding: rem(26);
}


.signup-form {
  //margin-bottom: rem(20);

  .form-check-label {
    font-weight: 600;

    &.normal {
      font-weight: normal;
      font-size: rem(14);
      line-height: rem(18);
    }
  }

  .signup-form-wrapper {
    padding: 0 $page-sides-padding-y;

    &.add-bg {
      background: rgba(29, 29, 27, 1);
    }

    .global-market-tc-container {
      .form-check {
        margin-bottom: rem(10);
        display: flex;
        align-items: center;

        .form-check-label {
          padding-left: rem(15);
        }

        .form-check-input {
          background-color: $primary-gray-soft;
          border: 2px solid #6b6b6b;
        }
      }

      .page-box-disclaimer {
        padding-left: 0;
      }
    }
  }

  .page-label {
    margin-bottom: rem(14);
  }

  .url-text {
    font-size: rem(12);
  }
}

.marketing-container {
  background-color: $primary-gray-dark-extra;
  padding: rem(20) $page-sides-padding-y;

  .page-box-disclaimer {
    padding-left: 0;
  }

  .form-check {
    margin-left: rem(20);
    margin-bottom: rem(10);
    display: flex;
    align-items: center;

    .form-check-label {
      padding-left: rem(15);
    }

    .form-check-input {
      background-color: $primary-gray-soft;
      border: 2px solid #6b6b6b;
    }
  }
}

.marketing-content {
  font-size: rem(12);
  line-height: rem(14);
  color: $white;

  &.hyperlinked {
    &>u {
      font-weight: 700;
    }

    &>b {
      text-decoration: underline;
    }
  }
  &.footer {
    margin-top: rem(30);
  }

  &.add-bottom {
    margin-bottom: rem(14);
  }
}

.loyalty-check {
  padding-bottom: rem(15);
}


.marketing-check-wrapper {
  .form-check {
    margin: rem(20);

    &.large {
      margin-left: 0;
    }

    .form-check-input:not(:checked) {
      background: transparent;
      border: 2px solid #6b6b6b;
    }
  }
}

.consents-step {
  .marketing-check-wrapper {
    margin: 0 (-$page-sides-padding-y);
    padding: $page-sides-padding-y;
    background-color: $dark;
  }

  &.country-za {
    padding-top: rem(40);

    .form-check {
      align-items: center;
    }
    input[type="checkbox"] {
      margin-top: rem(2);
    }

    .page-label {
      margin-bottom: rem(36);
    }

    .marketing-content.add-bottom-2 {
      margin-bottom: rem(26);
    }

    .marketing-check-wrapper {
      input[type="checkbox"] {
        margin-left: 0;
      }
    }
  }

  &.country-de {
    padding-top: rem(40);
  }
}

.dark-disclaimer {
  background: #1d1d1b;
}

.marketing-notification-preferences {
  background-image: url(../img/app-background-light.svg);
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: rem(42);
  padding: rem(28);

  &.no-bg {
    background: #1d1d1b;
  }

  .info-text {
    font-size: rem(12);
    font-weight: 400;
    line-height: rem(14);
    text-align: center;
  }
}

.signup-form-disclaimer {
  font-size: rem(14);
  line-height: rem(18);

  &.reset-passord {
    margin-bottom: rem(40);
  }
}

.signup-form-row {
  margin-bottom: rem(20);

  .genders {
    display: flex;
    justify-content: space-between;

    > input {
      display: none;

      &:checked + label {
        border-color: $primary-green;
      }
    }

    > label {
      padding: rem(16) rem(22);
      background-color: $dark;
      box-sizing: border-box;
      border: rem(2) solid #6b6b6b;
      border-radius: rem(8);
      font-size: rem(14);
      font-weight: 600;
      transition: border-color 0.25s ease-out;
    }
  }

  .form-check {
    align-items: flex-start;

    input[type="checkbox"] {
      background-color: transparent;
      border: rem(2) solid $primary-gray-light;
    }
  }

  &.marketing-check-wrapper {
    .form-check {
      margin-left: 0;
    }
  }
}

.signup-info {
  padding: 0 rem(50);
  margin-bottom: rem(20);
  text-align: center;
  font-size: rem(12);
  line-height: rem(14);

  a {
    color: $white;
    font-weight: 700;
  }

  &.hyperlinked {
    u {
      font-weight: 700;
    }

    b {
      text-decoration: underline;
    }
  }
}

.email-info {
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(14);
  color: $white;
}

.gender-container {
  height: rem(90);
  overflow: hidden;

  .gender-selector {
    height: rem(120);
    /* more place for scrollbar, is hidden under parent box */
    padding: rem(5) rem(16);
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    .gender-option {
      display: inline-block;
      height: rem(50);
      text-align: center;
      margin-left: rem(10);
    }
  }
}

.password-validation-container {
  .password-validation-row {
    display: inline-flex;
    margin-top: rem(5);

    .password-validation {
      color: $white;
      border-radius: rem(90);
      margin-right: rem(5);
      padding: rem(8) rem(12) rem(8) rem(12);

      font-style: normal;
      font-weight: normal;
      font-size: rem(12);
      line-height: rem(14);

      background: $primary-gray-dark-extra;
      border: rem(2) solid $primary-gray-light;

      display: flex;

      .bat-icon-check {
        font-size: rem(10);
        margin: auto rem(7) auto 0;
      }

      &.active {
        color: $white;
        background: $primary-green;
        border: rem(2) solid $primary-green;
      }
    }
  }
}

.ios-picker {
  font-style: normal;
  font-weight: 800;
  font-size: rem(20);
  line-height: rem(20);

  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;

  &.date-birthday-picker {
    margin-bottom: rem(38);
  }

  .wheel-picker-container {
    .wheel-picker-inner {
      font-size: rem(20);

      .wheel-picker-column {
        &:nth-child(2) {
          flex: 1.5;

          .wheel-picker-item {
            padding: 0;
          }
        }
      }

      .wheel-picker-item {
        &.wheel-picker-item-selected {
          color: $white;
        }
      }

      .wheel-picker-cursor {
        height: rem(50);
        left: auto;
        width: 85%;
        //background: $primary-gray-dark-extra;
        mix-blend-mode: normal;
        /* Dark Grey */

        border: rem(2) solid $primary-gray-light;
        border-radius: rem(8);

        &:before,
        &:after {
          height: 0;
        }
      }
    }
  }

  &.valid {
    .wheel-picker-cursor {
      border: 2px solid $primary-green !important;
    }
  }
}

.age-warning-disclaimer {
  box-sizing: border-box;
  padding: rem(15) rem(30);

  background-color: $dark;
  border: rem(5) solid #ffffff;

  span {
    display: block;
    font-size: rem(12);
    line-height: rem(14);
    text-align: center;

    &:first-child {
      font-size: rem(18);
      font-weight: 800;
      line-height: rem(18);
    }
  }
}

/* address form */
.form-address-wrapper {
  input {
  }
}

.form-address-panel {
  background: $primary-gray-dark-extra !important;
  border: rem(2) solid $primary-gray-light !important;
  box-sizing: border-box;
  border-radius: rem(8);

  font-weight: 400;
  font-size: rem(12);
  line-height: rem(14);
  color: $white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;

  .btn-address {
    background: $primary-gray-dark-extra;
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background: $primary-gray-dark-extra !important;
    border-bottom: rem(2) solid $primary-gray-light !important;

    &:last-child {
      border-bottom: none !important;
    }
  }
}

//PANEL
.sliding-panel-mid {
  padding: rem(20) $page-sides-padding-y;
  text-align: center;

  .sliding-panel-mid-icon {
    margin: rem(30) 0;
  }

  .sliding-panel-mid-info {
    margin-bottom: rem(20);
    font-size: rem(14);
    line-height: rem(18);

    &.disclaimer {
      padding: rem(10);
      background-color: $primary-gray-dark-extra;
      border: 2px solid $red;
      border-radius: rem(8);
    }
  }

  .sliding-panel-mid-value {
    background-color: $primary-gray-dark-extra;
    border-radius: $input-border-radius;
    padding-left: rem(20);
    padding-right: rem(20);
    min-height: rem(50);
    font-weight: 500;
  }

  .sliding-panel-mid-description {
    font-weight: 300;
    font-size: rem(14);
    line-height: rem(18);
    text-align: center;
    color: $primary-gray-medium;
    padding-left: rem(43);
    padding-right: rem(43);
  }
}

.phone-placeholder {
  &:disabled {
    background-color: $white;
    color: $primary-gray-medium;
    max-width: rem(50);
    border-radius: 0.5rem 0 0 0.5rem;
    padding-right: 0;
    border-right: none;
  }
}

#phoneNumber {
  border-radius: 0 0.5rem 0.5rem 0;
  border-left: none;
  padding-left: rem(3);
}

.signup-failure {
  .page-header {
    background-color: transparent;
    box-shadow: none;
  }
}

.marketing-panel {
  .form-check {
    border-top: 1px solid #6b6b6b;
    padding: 31px 50px;
    border-bottom: 1px solid #6b6b6b;
    margin: 1.25rem 0;
  }

  .sliding-panel-title {
    font-size: rem(18) !important;
    font-weight: 800 !important;
    line-height: rem(18) !important;
    text-align: center !important;
    padding: rem(24) 0 !important;
  }
}

/*
.step {
    height: 490px;
    visibility: visible;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition:all 500ms ease;
    
    &.step-hidden {
        visibility:hidden;
        height: 0px;
        overflow:hidden;
    }

}
*/

.signup-form-dob-wrapper {
  .signup-form-dob-content {
    display: flex;
    justify-content: space-between;

    background: #1d1d1b;
    mix-blend-mode: normal;
    /* Mint */

    border-radius: rem(8);
    padding: rem(15) rem(26);
    max-height: rem(50);

    font-size: rem(18);
    font-weight: 800;
    line-height: rem(18);
    text-transform: uppercase;
    border: rem(2) solid $primary-gray-light;

    &.valid {
      border: 2px solid $primary-green;
    }
  }

  .signup-form-dob-modal-container {
    .picker-modal-mask {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 9998;

      display: none;
      width: 100%;
      height: 100%;

      background: rgba(0, 0, 0, 0.6);
    }

    .picker-modal {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 9999;

      width: 100%;

      background-color: #efeff4;

      transform: translate(0, 100%);
      backface-visibility: hidden;
      transition: transform 0.3s;

      &.picker-modal-toggle {
        transform: translate(0, 0);
      }

      header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 0 15px;

        background-color: #fbf9fe;
        justify-content: space-between;

        .title {
          color: #1d1d1b;
        }

        span {
          text-decoration: none;
          color: blue;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
      }

      .wheel-picker-inner {
        padding: 0;
      }
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-21 13:1:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes dialog-slide-top {
  0% {
    //opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    //opacity: 1;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes dialog-slide-top {
  0% {
    //opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    //opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@-webkit-keyframes dialog-slide-down {
  0% {
    //opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    //opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes dialog-slide-down {
  0% {
    //opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    // opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.dialog-av {
  animation: dialog-slide-top 0.5s both;

  mix-blend-mode: normal;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: rem(20);
  width: rem(353);
  height: rem(433);
  margin: 0 auto;
  margin-top: rem(50);
  z-index: 10000;
  padding: 0 !important;
  justify-content: start !important;

  background-image: url(../img/app-background-light.svg);
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-height: 578px) {
    height: rem(353);
  }

  &.out {
    animation: dialog-slide-down 0.5s both;
  }

  .page-header-title {
    // border: rem(2) solid #2a2a2a;
    background: #1d1d1b;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    padding: rem(16.5) rem(25);
    position: relative;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: rem(30);
      line-height: rem(27);
      /* identical to box height, or 90% */

      text-align: center;
      text-transform: uppercase;

      color: $white;
    }

    h4 {
      font-style: normal;
      font-weight: 800;
      font-size: rem(18);
      line-height: rem(18);
      /* identical to box height, or 90% */

      text-align: center;
      text-transform: uppercase;

      color: $white;

      margin: 0;
    }

    .page-header-icon {
      position: absolute;
      right: rem(10);
      background: #1d1d1b;
      color: $white;
    }
  }

  h2 {
    margin: 0 !important;
  }

  .btn-link {
    display: none;
  }
}

.dialog-av-image {
  margin-top: rem(60);
  margin-bottom: rem(35);

  @media screen and (max-height: 578px) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

body.signup {
  .MuiPaper-root {
    background: none;
    padding: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    text-transform: none;

    .dialog-av-body {
      margin-bottom: 6rem;;
    }
  }

  .dialog-av-main > div > div {
    margin-top: calc($header-height + $top-navigator);
  }

  .dialog-av-body {
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
  
  .dialog-av-content-bold p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .dialog-av-content p {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
  
    text-align: center;
  
    /* White */
  
    color: #ffffff;
  }
}